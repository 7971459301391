import React from 'react';
import ImageCard from './ImageCard';
import { motion } from 'framer-motion';

const Gallery = ({ images, onDeleteImage, onStoreImage }) => {
  if (images.length === 0) {
    return null; // Don't render the gallery if there are no images
  }

  return (
    <motion.div
      className="mt-12"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">Gallery</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {images.map((image, index) => (
          <ImageCard
            key={index}
            imageUrl={image.url}
            generationTime={image.generationTime}
            createdAt={image.createdAt}
            onDelete={() => onDeleteImage(index)}
            onStore={() => onStoreImage(index)}
          />
        ))}
      </div>
    </motion.div>
  );
};

export default Gallery;
