import React, { useState } from 'react';
import { motion } from 'framer-motion';
import ImageModal from './ImageModal';

const ImageCard = ({ imageUrl, generationTime, createdAt, onDelete, onStore }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Handle image click to open modal
  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  // Handle modal close
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Format the creation date based on user's timezone
  const formattedDate = new Date(createdAt).toLocaleString();

  return (
    <motion.div
      className="relative bg-white dark:bg-gray-800 shadow-md rounded-md overflow-hidden cursor-pointer"
      whileHover={{ scale: 1.02 }}
      transition={{ type: 'spring', stiffness: 300 }}
    >
      <img
        src={imageUrl}
        alt="Generated"
        className="w-full h-64 object-cover"
        onClick={handleImageClick}
      />

      {/* Modal Popup */}
      <ImageModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        image={{ url: imageUrl }}
        generationTime={generationTime}
      />

      {/* Download Button on Hover */}
      <button
        onClick={(e) => {
          e.stopPropagation();
          window.open(imageUrl, '_blank'); // Open in new tab
        }}
        className="absolute top-2 right-2 bg-blue-600 text-white p-1 rounded-full hover:bg-blue-700 focus:outline-none"
        title="Download Image"
      >
        {/* Download Icon (SVG) */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v2a2 2 0 002 2h12a2 2 0 002-2v-2M12 12v9m0 0l-3-3m3 3l3-3" />
        </svg>
      </button>

      {/* Display Date and Time */}
      <div className="p-2">
        <p className="text-xs text-gray-500 dark:text-gray-400">{formattedDate}</p>
      </div>
    </motion.div>
  );
};

export default ImageCard;
