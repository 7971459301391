import React from 'react';
import Header from './components/Header';
import ImageGenerator from './components/ImageGenerator';
import { motion } from 'framer-motion';

function App() {
  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 transition-colors duration-500">
      <Header />
      <motion.main
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="container mx-auto"
      >
        <ImageGenerator />
      </motion.main>
    </div>
  );
}

export default App;
