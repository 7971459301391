import React from 'react';
import Modal from 'react-modal';
import { motion } from 'framer-motion';

Modal.setAppElement('#root'); // Accessibility requirement

const ImageModal = ({ isOpen, onRequestClose, image, generationTime }) => {
  if (!image) return null;

  const handleDownload = () => {
    // Open the image in a new tab for download
    window.open(image.url, '_blank');
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Image Modal"
      className="fixed inset-0 flex items-center justify-center p-4"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      <motion.div
        className="bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-lg max-w-3xl w-full"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.8, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <img src={image.url} alt="Generated" className="w-full h-auto" />
        <div className="p-4">
          <p className="text-sm text-gray-500 dark:text-gray-400 mb-2">
            Generated in {generationTime} ms
          </p>
          <div className="flex space-x-4">
            <button
              onClick={handleDownload}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
            >
              Download
            </button>
            <button
              onClick={() => onRequestClose()}
              className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      </motion.div>
    </Modal>
  );
};

export default ImageModal;
