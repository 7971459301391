import React, { useState, useEffect } from 'react';
import { fetchModels, generateImages } from '../services/api';
import Gallery from './Gallery'; // Import the updated Gallery component
import { ALLOWED_MODELS } from '../constants/models'; // Import the allowed models
import { motion } from 'framer-motion';
import Confetti from 'react-confetti';
import { useWindowSize } from 'react-use'; // To handle confetti dimensions

const ImageGenerator = () => {
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [prompt, setPrompt] = useState('');
  const [size, setSize] = useState('1024x1024');
  const [images, setImages] = useState([]); // Holds all generated images
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [confettiActive, setConfettiActive] = useState(false);

  const { width, height } = useWindowSize();

  // Fetch models on component mount
  useEffect(() => {
    const getModels = async () => {
      try {
        const modelsArray = await fetchModels();
        // Filter the models based on ALLOWED_MODELS
        const filteredModels = modelsArray.filter((model) =>
          ALLOWED_MODELS.includes(model.id)
        );
        if (filteredModels.length > 0) {
          setModels(filteredModels);
          setSelectedModel(filteredModels[0].id); // Set the first allowed model as default
        } else {
          setError('No allowed models available.');
        }
      } catch (err) {
        console.error('Error fetching models:', err);
        setError(err.message || 'Failed to fetch models.');
      }
    };
    getModels();
  }, []);

  // Handle form submission to generate images
  const handleGenerate = async (e) => {
    e.preventDefault();
    if (!prompt.trim()) {
      setError('Prompt cannot be empty.');
      return;
    }
    setLoading(true);
    setError(null);
    const startTime = Date.now(); // Start timer
    try {
      const payload = {
        model: selectedModel,
        prompt,
        n: 1,
        size,
        response_format: 'url',
        user: 'user123', // Replace with actual user identifier if needed
      };
      const imagesArray = await generateImages(payload);
      const endTime = Date.now(); // End timer
      const generationTime = endTime - startTime; // in milliseconds

      if (Array.isArray(imagesArray) && imagesArray.length > 0) {
        const newImage = {
          url: imagesArray[0].url,
          generationTime: generationTime, // Use calculated time
          createdAt: new Date(), // Capture current date and time
        };
        setImages((prevImages) => [...prevImages, ...[newImage]]); // Append new image
        setConfettiActive(true);
        // Stop confetti after 3 seconds
        setTimeout(() => {
          setConfettiActive(false);
        }, 3000);
      } else {
        setError('No images generated.');
      }
    } catch (err) {
      console.error('Error generating images:', err);
      setError(err.message || 'Image generation failed.');
    } finally {
      setLoading(false);
    }
  };

  // Handle deleting an image
  const handleDeleteImage = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    setImages(newImages);
  };

  // Handle storing an image (Currently logs to console)
  const handleStoreImage = (index) => {
    console.log(`Image at index ${index} stored (not yet implemented).`);
    // Future feature: Store the image in local storage or database
  };

  return (
    <motion.div
      className="p-6"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {confettiActive && <Confetti width={width} height={height} />}
      <form onSubmit={handleGenerate} className="space-y-4">
        {error && <div className="text-red-500">{error}</div>}
        <div>
          <label className="block text-gray-700 dark:text-gray-200">Select Model</label>
          <select
            value={selectedModel}
            onChange={(e) => setSelectedModel(e.target.value)}
            className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md p-2 bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
            required
            disabled={models.length === 0}
          >
            {models.length > 0 ? (
              models.map((model) => (
                <option key={model.id} value={model.id}>
                  {model.id}
                </option>
              ))
            ) : (
              <option disabled>No models available</option>
            )}
          </select>
        </div>
        <div>
          <label className="block text-gray-700 dark:text-gray-200">Prompt</label>
          <textarea
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md p-2 bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
            rows="3"
            placeholder="Describe the image you want to generate..."
            required
          ></textarea>
        </div>
        <div>
          <label className="block text-gray-700 dark:text-gray-200">Image Size</label>
          <select
            value={size}
            onChange={(e) => setSize(e.target.value)}
            className="mt-1 block w-full border border-gray-300 dark:border-gray-600 rounded-md p-2 bg-white dark:bg-gray-700 text-gray-800 dark:text-white"
            required
          >
            <option value="256x256">256x256</option>
            <option value="512x512">512x512</option>
            <option value="1024x1024">1024x1024</option>
          </select>
        </div>
        <button
          type="submit"
          className={`w-full bg-blue-500 dark:bg-blue-600 text-white p-2 rounded-md hover:bg-blue-600 dark:hover:bg-blue-700 transition-colors flex items-center justify-center ${
            loading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={loading || models.length === 0}
        >
          {loading ? (
            <>
              <div className="spinner mr-2"></div>
              Generating...
            </>
          ) : (
            'Generate Image'
          )}
        </button>
      </form>

      {/* Display Generated Images in Gallery */}
      <Gallery images={images} onDeleteImage={handleDeleteImage} onStoreImage={handleStoreImage} />
    </motion.div>
  );
};

export default ImageGenerator;
