import axios from 'axios';

const API_BASE_URL = 'https://api.rimunace.xyz/v1';
const API_KEY = process.env.REACT_APP_API_KEY;

if (process.env.NODE_ENV === 'development') {
  console.log('API Key Loaded:', API_KEY);
}

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${API_KEY}`, // Yes Bearer is important
  },
});

// Fetch available models
export const fetchModels = async () => {
  try {
    const response = await api.get('/models');
    if (process.env.NODE_ENV === 'development') {
      console.log('Fetch Models Response:', response.data); // Debugging
    }
    return response.data.data; // Extract the models array
  } catch (error) {
    console.error('Error fetching models:', error.response?.data || error.message);
    const errorMessage =
      error.response?.data?.error?.message ||
      error.response?.data?.message ||
      error.message ||
      'Failed to fetch models.';
    throw new Error(errorMessage);
  }
};

// Generate images
export const generateImages = async (payload) => {
  try {
    const response = await api.post('/images/generations', payload);
    if (process.env.NODE_ENV === 'development') {
      console.log('Generate Images Response:', response.data); // Debugging
    }
    return response.data.data; // Extract the images array
  } catch (error) {
    console.error('Error generating images:', error.response?.data || error.message);
    const errorMessage =
      error.response?.data?.error?.message ||
      error.response?.data?.message ||
      error.message ||
      'Image generation failed.';
    throw new Error(errorMessage);
  }
};
